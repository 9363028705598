import React, { Fragment } from 'react'
import Layout from '../layout'
import {
  SEO,
  Overview,
  Plan,
  Tour,
  More,
  Action,
  Feature,
  Benefits,
} from '../components'

import PaintIcon from '../assets/icons/paint.svg'
import CloudIcon from '../assets/icons/cloud.svg'
import TechSupportIcon from '../assets/icons/tech-support.svg'

import PrivateAppsOgImage from '../assets/images/private-apps-seo-image.png'

const Omnichannel = () => {
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Private Apps"
        description="Beautiful pieces to build the business card of your
        service."
        image={PrivateAppsOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Private Apps"
          subtitle={
            <>
              Secure &amp; encrypted tools
              <br />
              to build private enhancements
            </>
          }
          image={{
            name: 'privateAppsOverview',
            // maxHeight: 300,
          }}
          features={[
            {
              title: 'Secure Auth Protocol',
              description:
                'All the LiveChat services and APIs are secured with OAuth2.0, industry-standard protocol for authorization.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }getting-started/authorization/`,
            },
            {
              title: 'Granular Scopes',
              description:
                'Granular scope definitions are used across all the services to ensure secure and reliable access for apps and users.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }getting-started/authorization/scopes/`,
            },
            {
              title: 'Webhook Automations',
              description:
                'Private automations and schedulers can save loads of time. Integrate LiveChat with in-house software via webhooks.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }getting-started/guides/webhook-apps/`,
            },
          ]}
          button={{
            label: 'See how to build apps',
            link: `${process.env.GATSBY_DOCS_URL}getting-started/guides/`,
          }}
        />
      </div>

      <div className="u-bg-black-3 u-Pt-xl u-Pb-xs">
        <Feature
          title={
            <div className="u-Mt-2xl">
              Maximum security
              <br /> &amp; control over data
            </div>
          }
          description={
            <>
              Get full control over who and when gets access to LiveChat data
              with Private Apps. OAuth2.0 authorization protocol and the
              architecture of the LiveChat Platform make data safe &amp; sound.
            </>
          }
          image="secureApps"
        />
        <Feature
          inverse
          title={
            <Fragment>
              Automated work <br /> with webhooks
            </Fragment>
          }
          description={
            <>
              Webhooks allow to automate repetitive tasks and reduce amount of
              workload. Set up automatic lead assignment, data enrichment or
              integrate the LiveChat Platform with in-house software.
            </>
          }
          image="automateWork"
        />
      </div>
      <div className="u-bg-black2 u-Pt-xl u-Pb-xs">
        <Benefits
          content={[
            {
              icon: PaintIcon,
              label: 'Easily Customizable',
              description:
                'Use Private Apps to quickly modify and adapt LiveChat to meet specific needs.',
            },
            {
              icon: CloudIcon,
              label: 'Webhooks & automations',
              description:
                'Set up webhooks to automate work and integrate with in-house software.',
            },
            {
              icon: TechSupportIcon,
              label: 'Technical support',
              description:
                'Our highly skilled support teams are ready to get you up and running.',
            },
          ]}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-2xl u-Pb-xs">
        <Plan
          title={<Fragment>Multiple integration interfaces</Fragment>}
          subtitle={
            <Fragment>
              Create apps, enhancements, and addons <br /> that melt into
              exsiting company's environment.
            </Fragment>
          }
        />

        <Tour
          inverse
          features={[
            {
              image: 'privateAppsTour1',
              title: 'Private widgets at hand',
              description:
                'Load helpful widgets right into Customer Details panel. Update information with data from in-house CRM and provide Agents with internal tooling.',
            },
            {
              image: 'privateAppsTour2',
              title: 'Entire screen for private apps',
              description:
                'Big apps require more space. Leverage the area of the entire Agent’s screen and mount your app right in the main dock. Add notification badges to draw attention.',
            },
            {
              image: 'privateAppsTour3',
              title: 'Contextual integration interfaces',
              description:
                'Context is the king of usability. Provide smooth user experience with contextual buttons, widgets and other interactive elements.',
            },
          ]}
        />
      </div>
      <div className="u-bg-black-3 u-Pb-2xl">
        <More
          label={
            <>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </>
          }
          links={[
            {
              title: 'Programmable Chat',
              description:
                'Open messaging protocol for all types of communication.',
              link: '/programmable-chat/',
            },
            {
              title: 'Reporting APIs',
              description:
                'Build data-driven dashboards and advanced reporting tools.',
              link: '/data-and-reporting/',
            },
            {
              title: 'Open Chat Widget',
              description:
                'Beautiful widget for demanding developers and designers.',
              link: '/omnichannel/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="createLead"
          title={
            <>
              Customize LiveChat <br />
              with Private Apps
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: 'Open Developer Console',
          }}
          link={{
            link: `${process.env.GATSBY_DOCS_URL}messaging/`,
            label: 'browse the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default Omnichannel
